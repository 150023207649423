import { render, staticRenderFns } from "./EditSettlementRules.vue?vue&type=template&id=6b88a34c&scoped=true"
import script from "./EditSettlementRules.vue?vue&type=script&lang=js"
export * from "./EditSettlementRules.vue?vue&type=script&lang=js"
import style0 from "./EditSettlementRules.vue?vue&type=style&index=0&id=6b88a34c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b88a34c",
  null
  
)

export default component.exports