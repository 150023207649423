<template>
  <b-modal
    ref="priorityTracking"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="Priority Tracking"
    size="md"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
          <b-table
            :fields="fields"
            :items="myProvider"
            responsive="md"
            sticky-header="50vh"
            show-empty
            no-provider-filtering
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>

            <template #cell(user_name)="{ item }">
              {{ item.user_name }}
            </template>
            <template #cell(priority_changes)="row">
              <feather-icon
                icon="EyeIcon"
                class="text-primary cursor-pointer"
                size="20"
                @click="row.toggleDetails"
              />
            </template>
            <template #row-details="row">
              <b-card class="custom-card text-center">
                <b-row class="m-1 custom-header-row" >
                  <b-col class="text-left">Name</b-col>
                  <b-col>Initial Priority</b-col>
                  <b-col>
                    <feather-icon
                      icon="ArrowRightIcon"
                    />
                  </b-col>
                  <b-col>Final Priority</b-col>
                </b-row>
                <b-row class="m-1" v-for="change in row.item.priority_changes" :key="change.id">
                  <b-col class="text-left">{{ change.name}}</b-col>
                  <b-col>
                    <b-badge
                      variant="primary">
                      {{ change.initial_priority }}
                    </b-badge>
                  </b-col>
                  <b-col>
                    <feather-icon
                      icon="ArrowRightIcon"
                    />
                  </b-col>
                  <b-col>
                    <b-badge
                      variant="primary">
                      {{ change.final_priority }}
                    </b-badge>
                  </b-col>
                </b-row>
              </b-card>
            </template>
            <template #cell(created_at)="{ item }">
              {{ item.created_at | myGlobalDay }}
            </template>
          </b-table>
  </b-modal>
</template>
<script>
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
export default {
  data() {
    return {
      isBusy: false,
      items:[],
      fields: [
        {
          key: "user_name",
          label: "Updated by",
          thClass: "text-left",
          tdClass: "text-left",
          visible: true,
          class: "px-1"
        },
        {
          key: "created_at",
          label: "Updated at",
          thClass: "text-left",
          tdClass: "text-left",
          visible: true,
          thStyle:{
            width: "30%"
          },
          class: "px-1"
        },
        {
          key: "priority_changes",
          label: "Details",
          class: "text-center px-1",
          visible: true,
        },
      ],
    };
  },
  mounted() {
    this.toggleModal("priorityTracking");
  },
  methods: {

    async myProvider() {
      try {
        const data = await SettingsServiceSp.getPriorityTracking();
        this.items = data;
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.custom-card {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid #3498db;
}
.custom-header-row {
  background-color: #3498db;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px 0;
  margin-bottom: 10px;
}
</style>
