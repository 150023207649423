<template>
  <div>
    <b-modal
      v-model="showModal"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      size="xmd"
      centered
    >
      <template #modal-title>
        <h4 class="text-uppercase font-weight-bolder text-white">
          tracking of accounts to be
          {{ typeRule == "REMOVE" ? "removed" : "negotiated" }} -
          {{ accountName }}
        </h4>
      </template>
      <div class="p-2">
        <b-tabs
          v-if="typeRule == 'REMOVE'"
          lazy
          pills
          active-nav-item-class="bg-primary box-shadow-info"
        >
          <b-tab title="RULES" :title-link-class="[bgTabsNavs, 'sub-tab px-3']">
            <b-table
              ref="trackingTable"
              class="blue-scrollbar"
              :items="myProvider"
              :fields="visibleFields"
              :busy="isBusyResult"
              primary-key="id"
              responsive="md"
              sticky-header="50vh"
              show-empty
              no-provider-filtering
              striped
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #top-row>
                <b-th colspan="1"></b-th>
                <b-th class="py-0">
                  <b-row align-h="center">
                    <b-col cols="6" class="text-center p-0 bg-gradient-primary">
                      ITEM
                    </b-col>
                    <b-col cols="6" class="text-center p-0 bg-gradient-primary">
                      VALUE
                    </b-col>
                  </b-row>
                </b-th>
                <b-th class="py-0">
                  <b-row align-h="center">
                    <b-col cols="6" class="text-center p-0 bg-gradient-info">
                      ITEM
                    </b-col>
                    <b-col cols="6" class="text-center p-0 bg-gradient-info">
                      VALUE
                    </b-col>
                  </b-row>
                </b-th>
                <b-th class="py-0" v-if="typeRule == 'REMOVE'">
                  <b-row align-h="center">
                    <b-col
                      cols="6"
                      class="text-center p-0 bg-gradient-secondary"
                    >
                      OLD
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-center p-0 bg-gradient-secondary"
                    >
                      NEW
                    </b-col>
                  </b-row>
                </b-th>
              </template>
              <template #cell(nro_rule)="{ item }">
                <b-badge variant="warning" class="px-1">
                  RULE #{{ JSON.parse(item.rules).nro }}
                </b-badge>
              </template>
              <template #cell(old_rules)="{ value }">
                <template v-for="(data, index) in JSON.parse(value).subrules">
                  <div class="row" :key="index">
                    <div class="col-6">{{ ruleName(data.keyword) }}</div>
                    <div class="col-6" v-if="Array.isArray(data.value)">
                      <template v-for="(word, subIndex) in data.value">
                        <b-badge
                          style="margin: 3px 2px 0 0"
                          variant="light-primary"
                          :key="subIndex"
                        >
                          {{ word }}
                        </b-badge>
                      </template>
                    </div>
                    <div class="col-6" v-else>
                      <b-badge variant="light-primary">{{
                        data.value
                      }}</b-badge>
                    </div>
                  </div>
                  <hr
                    v-if="JSON.parse(value).subrules.length > 1"
                    :key="index"
                    style="border: 1px solid #969a9e; margin: 5px 0 !important"
                  />
                </template>
              </template>
              <template #cell(rules)="{ value }">
                <template v-for="(data, index) in JSON.parse(value).subrules">
                  <div class="row" :key="index">
                    <div class="col-6">{{ ruleName(data.keyword) }}</div>
                    <div class="col-6" v-if="Array.isArray(data.value)">
                      <template v-for="(word, subIndex) in data.value">
                        <b-badge
                          style="margin: 3px 2px 0 0"
                          variant="light-primary"
                          :key="subIndex"
                        >
                          {{ word }}
                        </b-badge>
                      </template>
                    </div>
                    <div class="col-6" v-else>
                      <b-badge variant="light-primary">{{
                        data.value
                      }}</b-badge>
                    </div>
                  </div>
                  <hr
                    v-if="JSON.parse(value).subrules.length > 1"
                    :key="index"
                    style="border: 1px solid #969a9e; margin: 5px 0 !important"
                  />
                </template>
              </template>
              <template #cell(result)="{ item }">
                <div class="row">
                  <div class="col-6">
                    <b>{{ JSON.parse(item.rules).result }}%</b>
                  </div>
                  <div class="col-6">
                    <b>{{ JSON.parse(item.old_rules).result }}%</b>
                  </div>
                </div>
              </template>
              <template #cell(created_by)="{ item }">
                <p class="m-0">{{ item.created_by }}</p>
                <p>{{ item.created_at | myGlobalDay }}</p>
              </template>
            </b-table>
          </b-tab>
          <b-tab
            title="RULES - RESULT"
            :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
          >
            <b-table
              ref="trackingTable"
              class="blue-scrollbar"
              :items="myProviderResult"
              :fields="myFieldsResult"
              :busy="isBusy"
              primary-key="id"
              responsive="md"
              sticky-header="50vh"
              show-empty
              no-provider-filtering
              striped
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #top-row>
                <b-th class="py-0">
                  <b-row align-h="center">
                    <b-col cols="4" class="text-center p-0 bg-gradient-danger">
                      LOW
                    </b-col>
                    <b-col cols="4" class="text-center p-0 bg-gradient-warning">
                      MEDIUM
                    </b-col>
                    <b-col cols="4" class="text-center p-0 bg-gradient-success">
                      HIGH
                    </b-col>
                  </b-row>
                </b-th>
                <b-th class="py-0">
                  <b-row align-h="center">
                    <b-col cols="4" class="text-center p-0 bg-gradient-danger">
                      LOW
                    </b-col>
                    <b-col cols="4" class="text-center p-0 bg-gradient-warning">
                      MEDIUM
                    </b-col>
                    <b-col cols="4" class="text-center p-0 bg-gradient-success">
                      HIGH
                    </b-col>
                  </b-row>
                </b-th>
              </template>
              <template #cell(old_result)="{ value }">
                <div class="row">
                  <template v-if="Object.keys(JSON.parse(value)).length == 3">
                    <div class="col-4">
                      {{ `0 - ${JSON.parse(value).low - 1}` }}
                    </div>
                    <div class="col-4">
                      {{
                        `${JSON.parse(value).low} - ${JSON.parse(value).medium}`
                      }}
                    </div>
                    <div class="col-4">
                      {{ `${JSON.parse(value).high} - 100` }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-4">
                      {{ `0 - ${JSON.parse(value).low}` }}
                    </div>
                    <div class="col-4">-</div>
                    <div class="col-4">
                      {{ `${JSON.parse(value).high} - 100` }}
                    </div>
                  </template>
                </div>
              </template>
              <template #cell(new_result)="{ value }">
                <div class="row">
                  <template v-if="Object.keys(JSON.parse(value)).length == 3">
                    <div class="col-4">
                      {{ `0 - ${JSON.parse(value).low - 1}` }}
                    </div>
                    <div class="col-4">
                      {{
                        `${JSON.parse(value).low} - ${JSON.parse(value).medium}`
                      }}
                    </div>
                    <div class="col-4">
                      {{ `${JSON.parse(value).high} - 100` }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-4">
                      {{ `0 - ${JSON.parse(value).low}` }}
                    </div>
                    <div class="col-4">-</div>
                    <div class="col-4">
                      {{ `${JSON.parse(value).high} - 100` }}
                    </div>
                  </template>
                </div>
              </template>
              <template #cell(created_at)="{ item }">
                <p>{{ item.created_at | myGlobalDay }}</p>
              </template>
            </b-table></b-tab
          >
        </b-tabs>
        <b-table
          v-else
          ref="trackingTable"
          class="blue-scrollbar"
          :items="myProvider"
          :fields="visibleFields"
          :busy="isBusyResult"
          primary-key="id"
          responsive="md"
          sticky-header="50vh"
          show-empty
          no-provider-filtering
          striped
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #top-row>
            <b-th colspan="1"></b-th>
            <b-th class="py-0">
              <b-row align-h="center">
                <b-col cols="6" class="text-center p-0 bg-gradient-primary">
                  ITEM
                </b-col>
                <b-col cols="6" class="text-center p-0 bg-gradient-primary">
                  VALUE
                </b-col>
              </b-row>
            </b-th>
            <b-th class="py-0">
              <b-row align-h="center">
                <b-col cols="6" class="text-center p-0 bg-gradient-info">
                  ITEM
                </b-col>
                <b-col cols="6" class="text-center p-0 bg-gradient-info">
                  VALUE
                </b-col>
              </b-row>
            </b-th>
            <b-th class="py-0" v-if="typeRule == 'REMOVE'">
              <b-row align-h="center">
                <b-col cols="6" class="text-center p-0 bg-gradient-secondary">
                  OLD
                </b-col>
                <b-col cols="6" class="text-center p-0 bg-gradient-secondary">
                  NEW
                </b-col>
              </b-row>
            </b-th>
          </template>
          <template #cell(nro_rule)="{ item }">
            <b-badge variant="warning" class="px-1">
              RULE #{{ JSON.parse(item.rules).nro }}
            </b-badge>
          </template>
          <template #cell(old_rules)="{ value }">
            <div
              v-for="(data, index) in JSON.parse(value).subrules"
              :key="index"
              class="row"
            >
              <div class="col-6">{{ ruleName(data.keyword) }}</div>
              <div class="col-6" v-if="Array.isArray(data.value)">
                <template v-for="(word, subIndex) in data.value">
                  <b-badge
                    style="margin: 3px 2px 0 0"
                    variant="light-primary"
                    :key="subIndex"
                  >
                    {{ word }}
                  </b-badge>
                </template>
              </div>
              <div class="col-6" v-else>
                <b-badge variant="light-primary">{{ data.value }}</b-badge>
              </div>
            </div>
          </template>
          <template #cell(rules)="{ value }">
            <div
              v-for="(data, index) in JSON.parse(value).subrules"
              :key="index"
              class="row"
            >
              <div class="col-6">{{ ruleName(data.keyword) }}</div>
              <div class="col-6" v-if="Array.isArray(data.value)">
                <template v-for="(word, subIndex) in data.value">
                  <b-badge
                    style="margin: 3px 2px 0 0"
                    variant="light-primary"
                    :key="subIndex"
                  >
                    {{ word }}
                  </b-badge>
                </template>
              </div>
              <div class="col-6" v-else>
                <b-badge variant="light-primary">{{ data.value }}</b-badge>
              </div>
            </div>
          </template>
          <template #cell(result)="{ item }">
            <div class="row">
              <div class="col-6">
                <b>{{ JSON.parse(item.rules).result }}%</b>
              </div>
              <div class="col-6">
                <b>{{ JSON.parse(item.old_rules).result }}%</b>
              </div>
            </div>
          </template>
          <template #cell(created_by)="{ item }">
            <p class="m-0">{{ item.created_by }}</p>
            <p class="m-0">{{ item.created_at | myGlobalDay }}</p>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import SettingsService from "@/views/specialists/sub-modules/analyst-department/views/settings/service/settings.service.js";
export default {
  data() {
    return {
      showModal: false,
      rules: [],
      myFields: [
        {
          key: "nro_rule",
          label: "# rule",
          visible: true,
          class: "text-center",
        },
        {
          key: "old_rules",
          label: "Old Rules",
          visible: true,
          class: "text-center",
        },
        {
          key: "rules",
          label: "new Rules",
          visible: true,
          class: "text-center",
        },
        {
          key: "result",
          label: "result",
          visible: false,
          class: "text-center",
        },
        {
          key: "created_by",
          label: "Updated by",
          visible: true,
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Updated at",
          visible: false,
        },
      ],
      myFieldsResult: [
        {
          key: "old_result",
          label: "Old Result",
          visible: true,
          class: "text-center",
        },
        {
          key: "new_result",
          label: "new Result",
          visible: true,
          class: "text-center",
        },
        {
          key: "created_by",
          label: "Updated by",
          visible: true,
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Updated at",
          visible: true,
        },
      ],
      isBusy: false,
      isBusyResult: false,
      convertOperator: {
        less_than: "<",
        less_equal_than: "<=",
        greater_than: ">",
        greater_equal_than: ">=",
        equal: "=",
      },
    };
  },
  props: {
    accountId: {
      type: Number,
      required: true,
    },
    typeRule: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
  },
  computed: {
    visibleFields() {
      return this.myFields.filter((item) => item.visible);
    },
  },
  async created() {
    this.showModal = true;
    this.myFields.forEach((element) => {
      if (element.key === "rule_result") {
        element.visible = this.typeRule.toLowerCase() === "remove";
      }
    });
    this.typeRule == "REMOVE"
      ? (this.myFields[3].visible = true)
      : (this.myFields[3].visible = false);
  },
  methods: {
    async myProvider() {
      const params = {
        accountId: this.accountId,
        typeRule: this.typeRule,
      };
      const { data } = await SettingsService.getRuleTracking(params);
      return data;
    },

    async myProviderResult() {
      const params = {
        accountId: this.accountId,
      };
      const { data } = await SettingsService.getRuleResultTracking(params);
      return data;
    },
    ruleName(keyword) {
      const mappings = {
        balance: "Balance",
        comments: "Comments",
        a_name: "Account name",
        a_type: "Account type",
        balance_percentage: "Balance percentage",
        a_status: "Account status",
        last_payment: "Last Payment",
        a_description: "Account Description",
        date: "Date Opened",
        a_type_detail: "Account Type Detail",
      };

      return mappings[keyword] || "--";
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>