<template>
  <b-modal
    v-model="onControl"
    ref="modalName"
    @hidden="closeModal()"
    hide-footer
    size="xmd"
    scrollable
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-title>
      <h4 class="text-uppercase font-weight-bolder text-white">
        <feather-icon icon="Edit3Icon" size="18" class="mr-50" />
        Edit - {{ nameRule || "" }} - Rules for remove
      </h4>
    </template>
    <b-container class="py-1">
      <p class="text-warning h5 mb-2" v-if="validateEdit(rules)">
        The marked rules indicate that they have a relationship with each other.
        Please review them carefully before editing.
      </p>

      <validation-observer ref="rulesForm">
        <b-row align-h="around" :key="keyRules">
          <b-col
            class="mb-2 p-0 rounded"
            sm="6"
            v-for="(data, index) in rules"
            :key="index"
            style="padding: 7px !important"
            :class="{ box: data.edit }"
          >
            <div
              class="border-light rounded py-1 px-2 h-100"
              style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3) !important"
              :style="{
                background: isDarkSkin ? '#17171A' : '#fff',
              }"
            >
              <div
                class="d-flex justify-content-between align-items-center border-bottom-secondary pb-1"
              >
                <h4 class="font-weight-bolder m-0 d-flex align-items-end">
                  Rule {{ index + 1 }}
                </h4>
                <b-button-group>
                  <b-button
                    v-if="!data.edit"
                    variant="primary"
                    v-b-tooltip.hover.v-primary="'Edit'"
                    size="sm"
                    @click="editRules(index)"
                    :disabled="data.disabled"
                  >
                    <feather-icon icon="EditIcon" size="12" />
                  </b-button>
                  <template v-else-if="data.edit">
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover.v-primary="'save'"
                      size="sm"
                      @click="saveRules(index)"
                    >
                      <feather-icon icon="SaveIcon" size="12" />
                    </b-button>
                    <b-button
                      variant="danger"
                      v-b-tooltip.hover.v-danger="'cancel'"
                      size="sm"
                      @click="editRules(index)"
                    >
                      <feather-icon icon="XIcon" size="12" />
                    </b-button>
                  </template>
                </b-button-group>
              </div>
              <b-row align-h="start" class="mt-1 px-0" align-v="center">
                <div
                  class="d-flex justify-content-between col-8 align-items-center w-100 my-50"
                  v-for="(keywordGroup, keyword) in groupSubrulesByKeyword(
                    data.rules.subrules
                  )"
                  :key="keyword"
                >
                  <!--name rule-->
                  <span class="d-flex small-size">
                    {{ convertKeywords(keyword) }}
                  </span>
                  <div class="d-flex justify-content-center align-items-center">
                    <!--condition or rule-->
                    <template v-for="(subrule, subIndex) in keywordGroup">
                      <div
                        :key="subIndex"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <validation-provider
                          v-if="typeData(subrule.value) === 'object'"
                          :ref="`rules_${data.id}`"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-tags
                            input-id="tags-basic"
                            separator=",;"
                            v-model="subrule.value"
                            :class="
                              errors.length > 0 ? 'border-danger rounded' : null
                            "
                            placeholder="Add Keyword by space, comma, or semicolon"
                            remove-on-delete
                            :disabled="!subrule.edit_value"
                          />
                        </validation-provider>
                        <b-badge
                          :variant="variantColor[subrule.value]"
                          class="mr-50 mr-md-2"
                          v-else-if="['CLOSED', 'OPEN'].includes(subrule.value)"
                        >
                          {{ subrule.value }}
                        </b-badge>
                        <validation-provider
                          v-else
                          :ref="`rules_${data.id}`"
                          rules="required|min: 1"
                          v-slot="{ errors }"
                        >
                          <b-input-group class="small-size-group">
                            <template #prepend>
                              <b-input-group-text>{{
                                convertOperator[subrule.operator]
                              }}</b-input-group-text>
                            </template>
                            <b-form-input
                              v-model.number="subrule.value"
                              placeholder="0"
                              :v-mask="'###'"
                              class="text-center small-size-input"
                              :disabled="!subrule.edit_value"
                              :class="[
                                subrule.edit_value ? 'bg-none' : '',
                                errors[0] ? 'border-danger rounded' : '',
                                isDarkSkin
                                  ? 'custom-bg-result-dark'
                                  : 'custom-bg-result',
                              ]"
                              min="1"
                              type="number"
                            />
                            <template #append>
                              <b-input-group-text>
                                <feather-icon
                                  :icon="typeIcon(keyword)"
                                  v-b-tooltip.hover
                                  :title="typeValue(keyword)"
                                ></feather-icon>
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                        </validation-provider>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="col-4 d-flex">
                  <!--result rule individual -->
                  <validation-provider
                    :vid="`result_${data.id}`"
                    :ref="`result_${data.id}`"
                    :rules="{ required: true, max_value: 100 }"
                    v-slot="{ errors }"
                    :custom-messages="customMessages"
                  >
                    <b-input-group class="small-size-group">
                      <template #prepend>
                        <b-input-group-text> = </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model.number="data.resultModel"
                        placeholder="0"
                        :v-mask="'###'"
                        :disabled="!data.edit"
                        class="small-size-input text-center"
                        :class="[
                          data.edit ? 'bg-none' : '',
                          errors[0] ? 'border-danger rounded' : '',
                          isDarkSkin
                            ? 'custom-bg-result-dark'
                            : 'custom-bg-result',
                        ]"
                        type="number"
                      />
                      <template #append>
                        <b-input-group-text>
                          <feather-icon icon="PercentIcon"></feather-icon>
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </div>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <!--result-->
        <b-row class="p-2" align-h="center">
          <div
            class="rounded w-100 px-1 pb-4 border-light col-8"
            style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3) !important"
          >
            <b-col class="mt-1 border-bottom-light mb-4">
              <h4 class="text-center font-weight-bolder mb-1">Result</h4>
            </b-col>
            <b-col sm="12" class="custom-margin px-3 pt-1 pb-2">
              <vue-slider
                v-model="value"
                :enable-cross="false"
                height="5px"
                :dot-style="dotStyle"
                :rail-style="railStyle"
                :process-style="processStyle"
                :tooltip-style="tooltipStyle"
                :step-style="stepStyle"
                :step-active-style="stepActiveStyle"
                :label-style="labelStyle"
                :label-active-style="labelActiveStyle"
                tooltip="none"
                :min="0"
                :max="101"
                :marks="marks"
                :process="process"
                :dot-options="dotOptions"
                @dragging="handleDragging"
              >
                <!-- Slot para personalizar los tooltips en los intervalos -->
                <template v-slot:process="{ style, index }">
                  <div class="vue-slider-process" :style="style">
                    <div
                      :class="[
                        'merge-tooltip',
                        'vue-slider-dot-tooltip-inner',
                        'vue-slider-dot-tooltip-inner-top',
                        { 'tooltip-low': index === 0 },
                        { 'tooltip-medium': index === 1 },
                        { 'tooltip-high': index === 1 && value.length === 3 },
                        { 'tooltip-high': index === 2 },
                      ]"
                      class="text-center"
                    >
                      <span v-if="index === 0">Low</span>
                      <span v-else-if="index === 1 && value.length === 3"
                        >High</span
                      >
                      <span v-else-if="index === 1">Medium</span>
                      <span v-else-if="index === 2">High</span><br />
                      <b>
                        {{ `${value[index]}% - ${value[index + 1] - 1}%` }}
                      </b>
                    </div>
                  </div>
                </template></vue-slider
              >
            </b-col>
            <b-col
              cols="5"
              class="mx-auto mt-5 text-center"
              align-self="center"
            >
              <b-button
                variant="outline-primary"
                class="text-center"
                @click="updateResult()"
                >SAVE</b-button
              >
            </b-col>
          </div>
        </b-row>
      </validation-observer>
    </b-container>
  </b-modal>
</template>

<script>
import VueSlider from "vue-slider-component";
import SettingService from "@/views/specialists/sub-modules/analyst-department/views/settings/service/settings.service";
import { mapGetters } from "vuex";
export default {
  props: {
    classification: {
      required: true,
      type: Number,
    },
    nameRule: {
      require: true,
      type: String,
      default: "",
    },
  },
  components: {
    VueSlider,
  },
  data() {
    return {
      staticContent: "Este contenido nunca cambiará",
      dynamicContent: "Este contenidso podría cambiar",
      message: "Hola mundo!",
      customMessages: {
        max_value: "The maximum value allowed is 100.", // Mensaje personalizado para max_value
      },
      onControl: false,
      dotStyle: {
        backgroundColor: "#007ad4",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
      },
      railStyle: {
        backgroundColor: "#def0ff",
      },
      processStyle: {
        backgroundColor: "#75cdff",
      },
      tooltipStyle: {
        backgroundColor: "#0090e7",
        color: "white",
      },
      stepStyle: {
        backgroundColor: "#f0f0f0",
        width: "1px",
        height: "1px",
        borderRadius: "0%",
        marginTop: "15px",
      },
      stepActiveStyle: {
        backgroundColor: "#fff",
      },
      labelStyle: {
        fontSize: "12px",
        color: "#0090e7",
      },
      labelActiveStyle: {
        fontWeight: "bold",
      },
      value: [],
      marks: {
        0: "0%",
        25: "25%",
        50: "50%",
        75: "75%",
        100: "100%",
      },
      process(val) {
        const process = [];

        if (val.length === 3) {
          // Si se envían 3 valores, mostramos Low y High
          process.push([val[0], val[1], { backgroundColor: "#ff3d60" }]);
          process.push([val[1], val[2], { backgroundColor: "#00d25b" }]);
        } else {
          // Si se envían 4 valores, mostramos Low, Medium y High
          process.push([val[0], val[1], { backgroundColor: "#ff3d60" }]);
          process.push([val[1], val[2], { backgroundColor: "#F1AE01" }]);
          process.push([val[2], val[3], { backgroundColor: "#00d25b" }]);
        }

        return process;
      },

      dotOptions: [],
      rules: [],
      keyRules: 0,
      variantColor: {
        CLOSED: "light-primary",
        OPEN: "light-success",
      },
      convertOperator: {
        less_than: "<",
        greater_than: ">",
        greater_equal_than: ">=",
        less_equal_than: "<=",
        equal: "=",
        open_account: "",
      },
      originRules: "",
      originRulesResult: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async mounted() {
    this.onControl = true;
    await this.getRules();
    await this.getResult();
  },
  methods: {
    handleDragging(index, newValue) {
      // Ignorar los extremos
      if (index === 0 || index === this.value.length - 1) return;

      // Calcular la diferencia mínima requerida entre valores adyacentes
      const minDifference = 1;

      // Calcular la diferencia entre el nuevo valor y los valores adyacentes
      const prevDifference = newValue - this.value[index - 1];
      const nextDifference = this.value[index + 1] - newValue;

      // Si el nuevo valor está demasiado cerca del valor anterior,
      // ajustamos el nuevo valor para que sea mayor
      if (prevDifference < minDifference) {
        this.value[index] = this.value[index - 1] + minDifference;
      }
      // Si el nuevo valor está demasiado cerca del valor siguiente,
      // ajustamos el nuevo valor para que sea menor
      else if (nextDifference < minDifference) {
        this.value[index] = this.value[index + 1] - minDifference;
      }

      // Asegurarnos de que los valores no sean iguales
      this.adjustValues();
    },
    adjustValues() {
      for (let i = 1; i < this.value.length - 1; i++) {
        if (this.value[i] <= this.value[i - 1]) {
          this.value[i] = this.value[i - 1] + 1;
        } else if (this.value[i] >= this.value[i + 1]) {
          this.value[i] = this.value[i + 1] - 1;
        }
      }
    },
    typeData(val) {
      return (typeof val).toLowerCase();
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async getRules() {
      try {
        this.addPreloader();
        const { data } = await SettingService.getRulesForRemoval({
          classification_id: this.classification,
          type_rule: "REMOVE",
        });
        this.rules = data;
        this.rules.forEach((element) => {
          // parsing data
          const parseRules = JSON.parse(element.rules);
          element.rules = parseRules;

          // add new attribute
          element.edit = false;
          element.disabled = false;
          element.editResult = false;
          element.resultModel = parseRules.result;

          // add "attributes" to data of subrules
          element.rules.subrules = parseRules.subrules.map((item) => {
            return {
              ...item,
              edit_value: false,
            };
          });
        });
        // copy to origin valusbes
        this.originRules = JSON.stringify(this.rules);

        this.keyRules++;
      } catch (error) {
        this.showWarningSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getResult() {
      try {
        const { data } = await SettingService.getRulesForRemovalResult({
          classification: this.classification,
        });
        this.originRulesResult = JSON.parse(data[0].result);
        const resultObj = JSON.parse(data[0].result);

        const { low = null, medium = null, high = null } = resultObj;
        let sumLow = medium ? low : low + 1;
        let sumMedium = medium ? medium + 1 : medium;
        this.value = [0, 101, sumLow, sumMedium].filter((val) => val !== null);
        if (low !== null && medium !== null && high !== null) {
          this.dotOptions = [
            { disabled: true },
            { disabled: false },
            { disabled: false },
            { disabled: true },
          ];
        } else if (low !== null && high !== null) {
          this.dotOptions = [
            { disabled: true },
            { disabled: false },
            { disabled: true },
          ];
        }
      } catch (error) {
        this.showWarningSwal(error);
      } finally {
        this.removePreloader()
      }
    },
    convertKeywords(data) {
      const item = {
        a_status: "ACCOUNT STATUS",
        last_payment: "LAST PAYMENT",
        balance: "BALANCE",
        date: "DATE OPENED",
        a_description: "ACCOUNT DESCRIPTION",
        a_name: "ACCOUNT NAME",
      };
      return item[data];
    },
    typeIcon(data) {
      const item = {
        last_payment: "CalendarIcon",
        balance: "DollarSignIcon",
        date: "CalendarIcon",
      };
      return item[data];
    },
    typeValue(data) {
      const item = {
        last_payment: "Year",
        balance: "Dollar",
        date: "Year",
      };
      return item[data];
    },
    groupSubrulesByKeyword(subrules) {
      const groupedSubrules = {};
      subrules.forEach((subrule) => {
        const { keyword } = subrule;
        if (!groupedSubrules[keyword]) {
          groupedSubrules[keyword] = [];
        }
        groupedSubrules[keyword].push(subrule);
      });
      return groupedSubrules;
    },
    async updateResult() {
      try {
        const confirm = await this.showConfirmSwal("Are you sure?", null);
        if (!confirm.value) {
          return;
        }
        this.addPreloader();
        const formdata = {
          classification: this.classification,
          value: this.value.slice(1, -1),
          old_result: this.originRulesResult,
          created_by: this.currentUser.user_id,
          criteria: this.classification,
        };
        await SettingService.updateResult(formdata);
        this.getResult();
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      } catch (error) {
        this.showWarningSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    editRules(index) {
      const status = !this.rules[index].edit;
      const originRules = JSON.parse(this.originRules);
      const ruleEdit = this.rules[index];

      this.rules.forEach((rule, index) => {
        const { resultModel, rules } = originRules[index];
        rule.resultModel = resultModel;
        rule.rules = rules;
        // Verificar si la relación de la regla coincide con la relación de edit
        if (rule.relation === ruleEdit.relation) {
          // Si coincide, establecer edit y editResult a true en el objeto edit
          rule.edit = !rule.edit;
          rule.editResult = !rule.editResult;
          rule.rules.subrules.forEach((item) => {
            item.edit_value = status;
          });
        } else {
          rule.disabled = !rule.disabled;
        }
      });

      this.keyRules++;
    },
    async saveRules(index) {
      try {
        const validateRules = await this.$refs.rulesForm.validate();
        let filteredArray = this.rules
          .map((item) => {
            return item.rules.subrules;
          })
          .map((innerArray) => {
            return innerArray.filter(
              (obj) => !isNaN(obj.value) && !Number.isNaN(parseFloat(obj.value))
            );
          })
          .filter((innerArray) => innerArray.length > 0);

        let transform = this.transformToRanges(filteredArray);
        this.isValid(transform);
        if (!validateRules) {
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        let {
          id: criteria_id,
          rules: newRules,
          resultModel,
        } = this.rules[index];
        newRules.result = resultModel;

        // new subrules
        const newSubrules = this.specificsValues(newRules.subrules);
        newRules = { ...newRules, subrules: newSubrules };

        // old subrules
        let { rules: oldRules } = JSON.parse(this.originRules)[index];
        const oldSubrules = this.specificsValues(oldRules.subrules);
        oldRules = { ...oldRules, subrules: oldSubrules };

        let edited_rules = this.rules.filter((item) => item.edit == true);
        let idFilterEdited = edited_rules.map((item) => item.id);

        const old_edited_rules = JSON.parse(this.originRules).filter((item) =>
          idFilterEdited.includes(item.id)
        );

        const params = {
          classification: this.classification,
          criteria_id,
          rules: newRules,
          old_rules: oldRules,
          created_by: this.currentUser.user_id,
          all_rules: this.rules,
          edited_rules: edited_rules,
          old_edited_rules: old_edited_rules,
        };
        const { status } = await SettingService.updateRulesForRemoval(params);
        if (status === 200) {
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successfully process"
          );
          await this.getRules();
        }
      } catch (error) {
        this.removePreloader();
        if (error.response && error.response.status == 422) {
          var errors = error.response.data.errors;
          var errorMessage = "";
          for (var key in errors) {
            errorMessage += errors[key][0] + "\n";
          }
          this.showWarningSwal("Validation error", errorMessage);
        } else {
          this.showWarningSwal("Validation error", error.message);
        }
      }
    },
    transformToRanges(rules) {
      const result = [];
      rules.forEach((rule) => {
        const keyword = rule[0].keyword;
        let min = 0;
        let max = Infinity;

        rule.forEach((subrule) => {
          const { value, operator } = subrule;
          const intValue = Math.round(parseFloat(value));
          if (!isNaN(intValue)) {
            if (operator === "greater_equal_than") {
              min = Math.max(intValue, min);
            } else if (operator === "less_than") {
              max = Math.min(intValue - 1, max);
            }
          }
        });

        result.push({
          range: { min, max },
          keyword,
        });
      });

      return result;
    },
    isValid(rules) {
      if (rules.length === 0) {
        throw new Error("No hay reglas para validar");
      }

      // Encontrar todos los keywords únicos en las reglas
      const uniqueKeywords = Array.from(
        new Set(rules.map((rule) => rule.keyword))
      );
      // Iterar sobre cada keyword y validar las reglas correspondientes
      for (let keyword of uniqueKeywords) {
        const filteredRules = rules.filter((rule) => rule.keyword === keyword);
        const sortedRanges = filteredRules
          .map((rule) => rule.range)
          .sort((a, b) => a.min - b.min);

        let lastMax = sortedRanges[0].max;

        for (let i = 1; i < sortedRanges.length; i++) {
          const currentMin = sortedRanges[i].min;

          if (currentMin > lastMax + 1) {
            throw new Error(
              `A gap has been identified in the ${this.convertKeywords(
                keyword
              )} item. The specified range is: ${lastMax + 1} - ${
                currentMin - 1
              }`
            );
          } else if (sortedRanges[i].min <= lastMax) {
            throw new Error(
              `Check the rules with the ${this.convertKeywords(
                keyword
              )} item, these have a conflict.`
            );
          }

          lastMax = Math.max(lastMax, sortedRanges[i].max);
        }
      }

      return true;
    },
    specificsValues(array) {
      return array.map((item) => {
        return {
          keyword: item.keyword,
          value: item.value,
          operator: item.operator || "",
        };
      });
    },
    validateEdit(rules) {
      return rules.some((item) => {
        return item.edit == true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.merge-tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -15px);
}
.tooltip-low {
  background: #ff3d60;
}
.tooltip-medium {
  background: #f1ae01;
}
.tooltip-high {
  background: #00d25b;
}
.custom-bg-result {
  background-color: #f0f0f0 !important;
}
.custom-bg-result-dark {
  background: #f8faf913 !important;
}
.bg-none {
  background: transparent !important;
}
.bg-white {
  background: #fff !important;
}
.custom-margin {
  margin-top: 10vh !important;
}
.small-size {
  font-size: 12px !important;
}
.small-size-input {
  max-width: 70px !important;
}
.small-size-group {
  transform: scale(0.9) !important;
}
.box {
  position: relative;
  background: repeating-conic-gradient(
    from var(--a),
    #f1ae01 0%,
    #f1ae01 5%,
    transparent 5%,
    transparent 40%,
    #f1ae01 50%
  );
  animation: animate 10s linear infinite;
}
@property --a {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes animate {
  0% {
    --a: 0deg;
  }
  100% {
    --a: 360deg;
  }
}
</style>