import moment from "moment";
export default [
  {
    key: "priority",
    label: "Priority",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "name",
    label: "Name",
    tdClass: "pt-1",
    visible: true,
    thStyle: {
      width: "20%",
    },
  },
  {
    key: "keywords",
    label: "Keywords",
    tdClass: "pt-1 text-center",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "reasons",
    label: "Reasons",
    tdClass: "pt-1 text-center",
    thClass: "text-center",
    visible: true,

  },
  {
    thClass: "text-center",
    key: "instructions",
    label: "Instructions",
    tdClass: "pt-1 text-center",
    visible: true,
  },
  {
    thClass: "text-center",
    key: "for_removal",
    label: "for removal",
    tdClass: "pt-1 text-center",
    visible: true,
  },
  {
    thClass: "text-center",
    key: "for_settlement",
    label: "for settlement",
    tdClass: "pt-1 text-center",
    visible: true,
  },
  {
    key: "created_at",
    label: "CREATED AT",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    formatter: (value) => {
      if (value) return moment(value).format("MM/DD/YYYY hh:mm A");
      return "-";
    },
    visible: false,
  }
];
