<template>
  <b-modal
    :title="getTitle"
    v-model="showModal"
    @hidden="closeModal"
    hide-footer
    no-close-on-backdrop
    centered
    size="xmd"
  >
    <template #modal-title>
      <h4 class="text-uppercase font-weight-bolder text-white">
        <feather-icon icon="Edit3Icon" size="18" class="mr-50" />
        {{ getTitle }}
      </h4>
    </template>
    <div v-if="rules.length > 0">
      <b-container no-body class="py-1">
        <p class="text-warning" v-if="validateEdit(rules)">
          The marked rules indicate that they have a relationship with each
          other. Please review them carefully before editing.
        </p>
        <validation-observer ref="rulesForm">
          <b-row align-h="around">
            <b-col
              class="mb-2 p-0"
              sm="6"
              v-for="(rule, index) in rules"
              :key="index"
              style="padding: 7px !important"
              :class="{ box: rule.edit }"
            >
              <div
                class="border-light rounded py-1 px-2 h-100"
                style="
                  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3) !important;
                "
                :style="{
                  background: isDarkSkin ? '#17171A' : '#fff',
                }"
              >
                <div
                  class="d-flex justify-content-between align-items-center border-bottom-secondary pb-1"
                >
                  <h4 class="font-weight-bolder m-0 d-flex align-items-end">
                    Rule {{ index + 1 }}
                  </h4>
                  <div>
                    <b-badge
                      :variant="backgroundResult(rule.rules.result)"
                      class="mr-2"
                      >{{ rule.rules.result }}
                    </b-badge>
                    <b-button-group>
                      <b-button
                        v-if="!rule.edit && rule.editable == 1"
                        variant="primary"
                        v-b-tooltip.hover.v-primary="'Edit'"
                        size="sm"
                        @click="editRules(index)"
                        :disabled="rule.disabled"
                      >
                        <feather-icon icon="EditIcon" size="12" />
                      </b-button>
                      <template v-else-if="rule.edit && rule.editable == 1">
                        <b-button
                          variant="primary"
                          v-b-tooltip.hover.v-primary="'save'"
                          size="sm"
                          @click="saveRules(index)"
                        >
                          <feather-icon icon="SaveIcon" size="12" />
                        </b-button>
                        <b-button
                          variant="danger"
                          v-b-tooltip.hover.v-danger="'cancel'"
                          size="sm"
                          @click="editRules(index)"
                        >
                          <feather-icon icon="XIcon" size="12" />
                        </b-button>
                      </template>
                    </b-button-group>
                  </div>
                </div>
                <div class="">
                  <template
                    v-for="(subrule, subindex) in Object.values(
                      rule.rules
                    ).flat()"
                  >
                    <template v-if="['balance'].includes(subrule.keyword)">
                      <div class="row" :key="subindex">
                        <div class="col-6 mt-1">
                          {{ ruleName(subrule.keyword) }}
                        </div>

                        <div class="col-6 mt-1">
                          <validation-provider
                            :vid="`result_${rule.id}`"
                            :ref="`result_${rule.id}`"
                            :rules="{ required: true }"
                            v-slot="{ errors }"
                          >
                            <b-input-group class="small-size-group">
                              <template #prepend>
                                <b-input-group-text>{{
                                  subrule.operator
                                }}</b-input-group-text>
                              </template>
                              <b-form-input
                                v-model.number="subrule.value"
                                placeholder="0"
                                :v-mask="'###'"
                                class="text-center small-size-input"
                                type="number"
                                :disabled="!subrule.editValue"
                                :class="[
                                  subrule.editValue ? 'bg-none' : '',
                                  isDarkSkin
                                    ? 'custom-bg-result-dark'
                                    : 'custom-bg-result',
                                ]"
                              />
                              <template #append>
                                <b-input-group-text>
                                  <feather-icon
                                    :icon="typeIcon(subrule.keyword)"
                                    v-b-tooltip.hover
                                    :title="typeValue(subrule.keyword)"
                                  ></feather-icon>
                                </b-input-group-text>
                              </template>
                            </b-input-group>
                            <small class="text-danger" v-if="errors[0]">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </div>
                      </div>
                    </template>

                    <!-- ACCOUNT TYPE, COMMENTS, ACCOUNT NAME -->
                    <template
                      v-else-if="
                        [
                          'a_type',
                          'comments',
                          'a_name',
                          'a_type_detail',
                          'p_status',
                        ].includes(subrule.keyword)
                      "
                    >
                      <div class="row" :key="subindex">
                        <div class="col-6 mt-1">
                          {{ ruleName(subrule.keyword) }}
                        </div>
                        <div class="col-6 mt-1">
                          <validation-provider
                            v-if="typeData(subrule.value) === 'object'"
                            :ref="`rules_${subrule.id}`"
                            :rules="{ required: true }"
                            v-slot="{ errors }"
                          >
                            <b-form-tags
                              input-id="tags-basic"
                              separator=",;"
                              placeholder="Add Keyword by space, comma, or semicolon"
                              remove-on-delete
                              v-model="subrule.value"
                              :disabled="!subrule.editValue"
                              :class="
                                errors.length > 0
                                  ? 'border-danger rounded'
                                  : null
                              "
                            />
                          </validation-provider>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </b-container>
    </div>
    <div v-else class="text-center my-3">
      <span> No rules for this account </span>
    </div>
  </b-modal>
</template>
<script>
import SettingService from "@/views/specialists/sub-modules/analyst-department/views/settings/service/settings.service.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showModal: false,
      rules: {},
      word: null,
      wordIndex: null,
      newSubrule: null,
      vMoney: {
        decimal: ".",
        thousands: ",",
        precision: 0,
        masked: false,
        prefix: "$ ",
        min: 0.0,
      },
      validateMoney: false,
      convertOperator: {
        less_than: "<",
        greater_than: ">",
        greater_equal_than: ">=",
        less_equal_than: "<=",
        equal: "=",
        open_account: "",
      },
    };
  },
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  async created() {
    await this.getRules();
    this.showModal = true;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getTitle() {
      return "EDIT - " + this.accountData.name + " - RULES FOR NEGOTIATE";
    },
  },
  methods: {
    async getRules() {
      try {
        this.addPreloader();
        const params = {
          classification_id: this.accountData.id,
          type_rule: "NEGOTIATE",
        };
        const { data } = await SettingService.getRules(params);
        this.rules = data.map((item) => {
          let parsedRules = JSON.parse(item.rules);
          return {
            ...item,
            edit: false,
            disabled: false,
            rules: {
              ...parsedRules,
              subrules: parsedRules.subrules.map((subrule) => {
                return {
                  ...subrule,
                  new: false,
                  newValue: null,
                  editValue: false,
                };
              }),
            },
          };
        });
        this.originRules = JSON.stringify(this.rules);
      } catch (error) {
        this.showWarningSwal(error);
      } finally {
        this.removePreloader()
      }
    },

    async updateRule(ruleId, subrule, indexSubrule, type = null) {
      const params = {
        ruleId: ruleId,
        keyword: subrule.keyword,
        value: this.newSubrule != null ? null : subrule.newValue,
        arrayValue: this.newSubrule == null ? [] : this.newSubrule.value,
        index: indexSubrule,
        userId: this.currentUser.user_id,
      };
      this.validateMoney = true;
      const validate = await this.$refs.form.validate();
      if (!validate) return;
      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;

      try {
        this.addPreloader();
        const { data } = await SettingService.updateRule(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successfully process"
          );
          this.reset(subrule);
          this.getRules();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oops!",
            "XIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async saveRules(index) {
      try {
        const validateRules = await this.$refs.rulesForm.validate();
        let filteredArray = this.rules
          .map((item) => {
            return item.rules.subrules;
          })
          .map((innerArray) => {
            return innerArray.filter((obj) => Number.isInteger(obj.value));
          })
          .filter((innerArray) => innerArray.length > 0);

        let transform = this.transformToRanges(filteredArray);
        this.isValid(transform);
        if (!validateRules) {
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        let { id: criteria_id, rules: newRules } = this.rules[index];

        // new subrules
        const newSubrules = this.specificsValues(newRules.subrules);
        newRules = { ...newRules, subrules: newSubrules };

        // old subrulesj
        let { rules: oldRules } = JSON.parse(this.originRules)[index];
        const oldSubrules = this.specificsValues(oldRules.subrules);
        oldRules = { ...oldRules, subrules: oldSubrules };

        let edited_rules = this.rules.filter((item) => item.edit == true);
        let idFilterEdited = edited_rules.map((item) => item.id);

        const old_edited_rules = JSON.parse(this.originRules).filter((item) =>
          idFilterEdited.includes(item.id)
        );

        const params = {
          classification: this.accountData.id,
          criteria_id,
          rules: newRules,
          old_rules: oldRules,
          created_by: this.currentUser.user_id,
          all_rules: this.rules,
          edited_rules: edited_rules,
          old_edited_rules: old_edited_rules,
        };
        const { status } = await SettingService.updateRule(params);
        if (status === 200) {
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successfully process"
          );
          await this.getRules();
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
        if (error.response && error.response.status == 422) {
          var errors = error.response.data.errors;
          var errorMessage = "";
          for (var key in errors) {
            errorMessage += errors[key][0] + "\n";
          }
          this.showWarningSwal(errorMessage);
        } else {
          this.showWarningSwal("Validation error", error.message);
        }
      }
    },

    editValue(subrule) {
      subrule.newValue = subrule.value;
      subrule.edit = true;
    },

    ruleName(keyword) {
      const mappings = {
        balance: "Balance",
        comments: "Comments",
        a_name: "Account name",
        a_type: "Account type",
        balance_percentage: "Balance percentage",
        a_type_detail: "Account Type Detail",
        p_status: "Payment Status",
        c_limit: "Credit Limit",
      };

      return mappings[keyword] || "--";
    },

    reset(subrule) {
      this.word = null;
      this.wordIndex = null;
      this.newSubrule = null;
      subrule.edit = false;
      subrule.new = false;
      subrule.newValue = null;
    },

    closeModal() {
      this.$emit("closeModal");
    },

    editRules(index) {
      const status = !this.rules[index].edit;
      const originRules = JSON.parse(this.originRules);
      const ruleEdit = this.rules[index];

      this.rules.forEach((rule, index) => {
        const { resultModel, rules } = originRules[index];
        rule.resultModel = resultModel;
        rule.rules = rules;
        // Verificar si la relación de la regla coincide con la relación de edit
        if (rule.relation === ruleEdit.relation) {
          // Si coincide, establecer edit y editResult a true en el objeto edit
          rule.edit = !rule.edit;
          rule.editResult = !rule.editResult;
          rule.rules.subrules.forEach((item) => {
            item.editValue = status;
          });
        } else {
          rule.disabled = !rule.disabled;
        }
      });

      this.keyRules++;
    },

    specificsValues(array) {
      return array.map((item) => {
        return {
          keyword: item.keyword,
          value: item.value,
          operator: item.operator || "",
        };
      });
    },
    typeData(val) {
      return (typeof val).toLowerCase();
    },
    typeIcon(data) {
      const item = {
        // a_status: "CalendarIcon",
        last_payment: "CalendarIcon",
        balance: "DollarSignIcon",
        date: "CalendarIcon",
        // a_description: "WatchIcon",
        // a_name: "WatchIcon",
      };
      return item[data];
    },
    typeValue(data) {
      const item = {
        last_payment: "Year",
        balance: "Dollar",
        date: "Year",
      };
      return item[data];
    },
    backgroundResult(data) {
      const item = {
        "NEEDS CONFIRMATION": "light-warning",
        SETTLE: "light-success",
        "NOT SETTLE": "light-danger",
      };
      return item[data];
    },
    transformToRanges(rules) {
      const result = [];

      const singleRules = rules.filter((rule) => rule.length === 1); // Filtrar solo las reglas individuales

      singleRules.forEach((rule) => {
        const keyword = rule[0].keyword;

        let min = 0;
        let max = Infinity;

        rule.forEach((subrule) => {
          const { value, operator } = subrule;
          const intValue = parseInt(value);

          if (!isNaN(intValue)) {
            if (operator === ">=") {
              min = Math.max(intValue, min);
            } else if (operator === "<") {
              max = Math.min(intValue - 1, max);
            }
          }
        });

        result.push({
          range: { min, max },
          keyword,
        });
      });

      return result;
    },
    isValid(rules) {
      if (rules.length === 0) {
        throw new Error("No hay reglas para validar");
      }

      // Encontrar todos los keywords únicos en las reglas
      const uniqueKeywords = Array.from(
        new Set(rules.map((rule) => rule.keyword))
      );
      // Iterar sobre cada keyword y validar las reglas correspondientes
      for (let keyword of uniqueKeywords) {
        const filteredRules = rules.filter((rule) => rule.keyword === keyword);
        const sortedRanges = filteredRules
          .map((rule) => rule.range)
          .sort((a, b) => a.min - b.min);

        let lastMax = sortedRanges[0].max;
        const hasZeroInfinityRange = filteredRules.some(
          (rule) => rule.range.min === 0 && rule.range.max === Infinity
        );
        if (hasZeroInfinityRange) {
          return true;
        }
        for (let i = 1; i < sortedRanges.length; i++) {
          const currentMin = sortedRanges[i].min;

          if (currentMin > lastMax + 1) {
            throw new Error(
              `A gap has been identified in the ${this.ruleName(
                keyword
              )} item. The specified range is: ${lastMax + 1} - ${
                currentMin - 1
              }`
            );
          } else if (sortedRanges[i].min <= lastMax) {
            throw new Error(
              `Check the rules with the ${this.ruleName(
                keyword
              )} item, these have a conflict between the ranges: ${
                sortedRanges[i - 1].max
              } - ${sortedRanges[i].min}`
            );
          }

          lastMax = Math.max(lastMax, sortedRanges[i].max);
        }
      }

      return true;
    },
    validateEdit(rules) {
      return rules.some((item) => {
        return item.edit == true;
      });
    },
  },
};
</script>
<style scoped>
.custom-bg-result {
  background-color: #f0f0f0 !important;
}
.custom-bg-result-dark {
  background: #f8faf913 !important;
}
.bg-none {
  background: transparent !important;
}
.small-size-group {
  transform: scale(0.9) !important;
}
.box {
  position: relative;
  background: repeating-conic-gradient(
    from var(--a),
    #0090e7 0%,
    #0090e7 5%,
    transparent 5%,
    transparent 40%,
    #0090e7 50%
  );
  animation: animate 10s linear infinite;
}
@property --a {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes animate {
  0% {
    --a: 0deg;
  }
  100% {
    --a: 360deg;
  }
}
</style>