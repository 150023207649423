<template>
  <b-modal
    ref="listInfo"
    modal-class="modal-primary"
    title-class="h3 text-white"
    :title="title"
    size="md"
    @hidden="closeModal()"
    hide-footer
    centered
  >
    <ul class="p-1">
      <li v-for="(item, index) in list" :key="index" class="list-unstyled">
        <div class="d-flex align-items-start">
          <feather-icon
            class="flex-shrink-0"
            icon="CheckCircleIcon"
            :class="`mr-1 ${type == 1 ? 'text-success' : 'text-primary'}`"
            size="10"
            style="margin-top: 5px"
          />
          <span>
            {{ item.value | firstLetterCapitalize }}
          </span>
        </div>
      </li>
    </ul>
  </b-modal>
</template>
<script>
export default {
  props: {
    data: Object,
    type: Number,
  },
  filters: {
    firstLetterCapitalize(valor) {
      if (!valor) return;
      return valor.charAt(0).toUpperCase() + valor.slice(1).toLowerCase();
    },
  },
  computed: {
    list() {
      return this.type == 1 ? this.data.reasons : this.data.instructions;
    },
    title() {
      if (this.type == 1) {
        return `REASONS FOR ${this.data.name}`;
      } else {
        return `INSTRUCTIONS FOR ${this.data.name}`;
      }
    },
  },
  mounted() {
    this.toggleModal("listInfo");
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>