<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ FilterSlot.totalRows }} entries
            </span>
            <feather-icon
              class="cursor-pointer mx-2"
              icon="RefreshCcwIcon"
              size="20"
              @click="refreshTable"
            />
            <b-button
              @click="savePriority"
              :variant="changesMade ? 'warning' : 'outline-warning'"
              class="mx-2"
              :class="changesMade ? 'grow-up' : null"
              :disabled="!changesMade"
            >
              Save Priority Changes
            </b-button>
            <b-button
              @click="showPriorityTracking = true"
              variant="outline-warning"
              class="mx-2"
            >
              Priority Tracking
            </b-button>
          </b-col>
          <b-col cols="12" class="text-center" md="6">
            <h5 class="text-primary font-weight-bolder">
              * Drag and drop the rows of the table to sort the reading priority of negative accounts.
            </h5>
          </b-col>
          <!-- <b-col cols="12" md="6">
            <div
              class="d-flex align-items-center justify-content-end align-items-center"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-if="FilterSlot.filterPrincipal.type === 'input'"
                  v-model="FilterSlot.filterPrincipal.model"
                  :type="FilterSlot.filterPrincipal.inputType"
                  :placeholder="FilterSlot.filterPrincipal.placeholder"
                  :class="FilterSlot.filterPrincipal.class"
                  @keyup.enter="myProvider"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="myProvider">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col> -->
        </b-row>
      </div>
      <div class="table-responsive">
        <div v-if="isLoading" class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
        <b-table-simple
          v-else
          class="table-item"
          responsive="md"
          sticky-header="50vh"
        >
          <b-thead class="fixed-header">
            <b-th v-for="field in myFields" :key="field.id">
              {{ field.label }}
            </b-th>
          </b-thead>
          <draggable
            :class="{ [`cursor-grabbing`]: drag === true }"
            v-model="negativeItems"
            group="items"
            @start="drag = true"
            @end="drag = false"
            tag="tbody"
            drag-class="drag"
            ghost-class="ghost"
          >
            <b-tr v-for="item in negativeItems" :key="item.id" class="item-row">
              <b-td> {{ item.priority }}</b-td>
              <b-td>
                {{ item.name }}
              </b-td>
              <b-td>
                <b-button
                  :variant="item.keywords ? 'primary' : 'outline-primary'"
                  @click="openKeyword(item)"
                  size="sm"
                >
                  <img
                    src="@/assets/images/icons/keywords.svg"
                    width="20px"
                    height="20px"
                    class="custom-icon mr-50"
                  />
                  <b-badge variant="danger">{{
                    item.keywords ? item.keywords.length : 0
                  }}</b-badge>
                </b-button>
              </b-td>
              <b-td>
                <b-button
                  size="sm"
                  :variant="item.reasons ? 'secondary' : 'outline-secondary'"
                  @click="openModalClassificationNcr(item, 'Reasons')"
                >
                  <img
                    src="@/assets/images/icons/reasons.svg"
                    width="20px"
                    height="20px"
                    class="custom-icon mr-50"
                  />
                  <b-badge variant="danger">{{
                    item.reasons ? item.reasons.length : 0
                  }}</b-badge>
                </b-button>
              </b-td>
              <b-td>
                <b-button
                  size="sm"
                  :variant="item.instructions ? 'info' : 'outline-info'"
                  @click="openModalClassificationNcr(item, 'Instrucciones')"
                >
                  <img
                    src="@/assets/images/icons/instruccion.svg"
                    width="20px"
                    height="20px"
                    class="custom-icon mr-50"
                  />
                  <b-badge variant="danger">{{
                    item.instructions ? item.instructions.length : 0
                  }}</b-badge>
                </b-button>
              </b-td>
              <b-td>
                <b-button-group>
                  <b-button
                    variant="warning"
                    size="sm"
                    v-b-tooltip.hover.top="'Edit rules'"
                   @click="openModalForRemoval(item)"
                    :disabled="!item.isForRemoval"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="info"
                    size="sm"
                    @click="trackingModal(item, 'REMOVE')"
                    v-b-tooltip.hover.top="'Tracking'"
                    :disabled="!item.isForRemoval"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-button-group>
              </b-td>
              <b-td>
                <b-button-group>
                  <b-button
                    variant="warning"
                    size="sm"
                    v-b-tooltip.hover.top="'Edit rules'"
                    @click="settlementModal(item)"
                    :disabled="!item.isForNegotiate"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="info"
                    size="sm"
                    @click="trackingModal(item, 'NEGOTIATE')"
                    v-b-tooltip.hover.top="'Tracking'"
                    :disabled="!item.isForNegotiate"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-button-group>
              </b-td>
            </b-tr>
          </draggable>
          <b-tr v-for="item in positiveItems" :key="item.id">
            <b-td> {{ item.priority }}</b-td>
            <b-td>
              {{ item.name }}
            </b-td>
            <b-td>
              <b-button
                size="sm"
                :variant="item.keywords ? 'primary' : 'outline-primary'"
                @click="openKeyword(item)"
                disabled
              >
                <img
                  src="@/assets/images/icons/keywords.svg"
                  width="20px"
                  height="20px"
                  class="custom-icon mr-50"
                />
                <b-badge variant="danger">{{
                  item.keywords ? item.keywords.length : 0
                }}</b-badge>
              </b-button>
            </b-td>
            <b-td>
              <b-button
                size="sm"
                :variant="item.reasons ? 'secondary' : 'outline-secondary'"
                @click="openModalClassificationNcr(item, 'Reasons')"
                disabled
              >
                <img
                  src="@/assets/images/icons/reasons.svg"
                  width="20px"
                  height="20px"
                  class="custom-icon mr-50"
                />
                <b-badge variant="danger">{{
                  item.reasons ? item.reasons.length : 0
                }}</b-badge>
              </b-button>
            </b-td>
            <b-td>
              <b-button
                size="sm"
                :variant="item.instructions ? 'info' : 'outline-info'"
                @click="openModalClassificationNcr(item, 'Instrucciones')"
                disabled
              >
                <img
                  src="@/assets/images/icons/instruccion.svg"
                  width="20px"
                  height="20px"
                  class="custom-icon mr-50"
                />
                <b-badge variant="danger">{{
                  item.instructions ? item.instructions.length : 0
                }}</b-badge>
              </b-button>
            </b-td>
            <b-td>
                <b-button-group>
                  <b-button
                    variant="warning"
                    size="sm"
                    v-b-tooltip.hover.top="'Edit rules'"
                    @click="openModalForRemoval(item)"
                    :disabled="!item.isForRemoval"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="info"
                    size="sm"
                    @click="trackingModal(item, 'REMOVE')"
                    v-b-tooltip.hover.top="'Tracking'"
                    :disabled="!item.isForRemoval"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-button-group>
              </b-td>
              <b-td>
                <b-button-group>
                  <b-button
                    variant="warning"
                    size="sm"
                    v-b-tooltip.hover.top="'Edit rules'"
                    @click="settlementModal(item)"
                    :disabled="!item.isForNegotiate"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="info"
                    size="sm"
                    @click="trackingModal(item, 'NEGOTIATE')"
                    v-b-tooltip.hover.top="'Tracking'"
                    :disabled="!item.isForNegotiate"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-button-group>
              </b-td>
          </b-tr>
        </b-table-simple>
      </div>
    </b-card>
    <edit-settlement-rules
      v-if="showSettlementModal"
      :accountData="accountData"
      @closeModal="showSettlementModal = false"
    />
    <tracking-rules
      v-if="showTracking"
      :accountId="accountId"
      :typeRule="typeRule"
      :accountName="accountName"
      @closeModal="showTracking = false"
    />
    <AddKeywords
      @close="showModal = false"
      v-if="showModal && itemTypeNrc"
      :item="itemTypeNrc"
      @refresh="refreshTable()"
    />
    <PriorityTracking
      v-if="showPriorityTracking"
      @close="showPriorityTracking = false"
    />
    <EditClassificationNcr
      v-if="showModal && classificationNcr.item"
      @close="closeModalClassificationNcr"
      @refresh="refreshTable"
      :classificationNcr="classificationNcr.item"
      :typeClassification="classificationNcr.type"
    />
    <ModalForRemoval
      v-if="showModalForRemoval"
      @closeModal="showModalForRemoval = false"
      :classification="classificationId"
      :nameRule="nameRuleSelected"
    />
  </div>
</template>
<script>
import AddKeywords from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/AddKeywords.vue";
import Fields from "@/views/specialists/sub-modules/analyst-department/views/settings/data/fields";
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import ModalInstructionsReasons from "@/views/specialists/sub-modules/analyst-department/views/settings/views/modals/classification-ncr/ModalInstructionsReasons.vue";
import EditSettlementRules from "@/views/specialists/sub-modules/analyst-department/views/settings/views/modals/classification-ncr/rules/EditSettlementRules.vue";
import TrackingRules from "@/views/specialists/sub-modules/analyst-department/views/settings/views/modals/classification-ncr/rules/TrackingRules.vue";
import EditClassificationNcr from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/EditClassificationNcr.vue";
import PriorityTracking from "@/views/specialists/sub-modules/analyst-department/views/settings/views/modals/classification-ncr/PriorityTracking.vue";
import ModalForRemoval from "@/views/specialists/sub-modules/analyst-department/views/settings/views/modals/classification-ncr/rules/EditRemovedRules.vue";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  name: "ClassificationNcr",
  components: {
    ModalInstructionsReasons,
    draggable,
    EditSettlementRules,
    TrackingRules,
    PriorityTracking,
    EditClassificationNcr,
    draggable,
    AddKeywords,
    ModalForRemoval,
  },
  data() {
    return {
      isLoading: false,
      changesMade: false,
      itemsId: [],
      drag: false,
      items: [],
      negativeItems: [],
      positiveItems: [],
      drag: false,
      initialItems: [],
      Fields,
      dataList: [],
      showPriorityTracking: false,
      showModal: false,
      itemTypeNrc: null,
      classificationNcr: {
        item: null,
        type: null,
      },
      isBusy: false,
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: null,
        toPage: null,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
      showSettlementModal: false,
      accountData: {},
      showTracking: false,
      accountId: null,
      showModalForRemoval: false,
      classificationId: null,
      nameRuleSelected: null,
      typeRule: null,
      accountName: null,
      notForRemoval:[
        "OPEN ACCOUNTS"
      ],
      notForNegotiate:[
        "FORECLOSURE",
        "SHORT SALE",
        "CHILD SUPPORT",
        "STUDENT LOAN"
      ]
    };
  },
  async mounted() {
    await this.myProvider();
  },
  computed: {
    myFields() {
      return this.Fields.filter((x) => x.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    priorityStatus() {},
  },
  methods: {
    handleItemChanges(newValue, oldValue) {
      const changesDetected = !newValue.every(
        (item, index) => item.id === this.initialItems[index].id
      );
      this.changesMade = changesDetected;
    },
    async myProvider() {
      this.isLoading = true;
      try {
        const { model } = this.FilterSlot.filterPrincipal;
        const { currentPage, perPage } = this.FilterSlot.paginate;
        const result = await SettingsServiceSp.getClassificationNcr({
          search: model,
          page: currentPage,
          perPage: perPage,
          type: 1,
        });

        const { data, current_page, to, total, from } = result;
        this.FilterSlot.startPage = from;
        this.FilterSlot.toPage = to;
        this.FilterSlot.paginate.currentPage = current_page;
        this.FilterSlot.totalRows = total;
        this.negativeItems = data.filter(
          (item) => item.type_account == "NEGATIVE"
        );
        this.negativeItems.map((item)=>{
          this.notForRemoval.includes(item.name) ? item.isForRemoval = false : item.isForRemoval = true;
          this.notForNegotiate.includes(item.name) ? item.isForNegotiate = false : item.isForNegotiate = true;
          return item;
        });
        this.positiveItems = data.filter(
          (item) => item.type_account == "POSITIVE"
        );
        this.positiveItems.map((item)=>{
          this.notForRemoval.includes(item.name) ? item.isForRemoval = false : item.isForRemoval = true;
          this.notForNegotiate.includes(item.name) ? item.isForNegotiate = false : item.isForNegotiate = true;
          return item;
        })
        this.initialItems = [...this.negativeItems];
        this.$watch("negativeItems", this.handleItemChanges, { deep: true });
      } catch (ex) {
        console.log(ex);
      }
      this.isLoading = false;
      return [];
    },
    async savePriority() {
      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          this.addPreloader();
          this.itemsId = this.negativeItems.map((item) => item.id);
          await SettingsServiceSp.modifyPriority({
            itemsId: this.itemsId,
            user_id: this.currentUser.user_id,
          });
          this.showSuccessSwal();
        } catch (error) {
          this.showErrorSwal();
          console.log(error);
        } finally {
          this.removePreloader();
        }
      }
      await this.refreshTable();
    },
    openKeyword(item) {
      this.itemTypeNrc = item;
      this.showModal = true;
    },
    async refreshTable() {
      this.FilterSlot.filterPrincipal.model = "";
      await this.myProvider();
    },
    settlementModal(item) {
      this.accountData = item;
      this.showSettlementModal = true;
    },
    trackingModal(item, type) {
      this.typeRule = type;
      this.accountId = item.id;
      this.accountName = item.name;
      this.showTracking = true;
    },
    openModalClassificationNcr(item, type) {
      this.showModal = true;
      this.classificationNcr.item = item;
      this.classificationNcr.type = type;
    },
    closeModalClassificationNcr() {
      this.classificationNcr.item = null;
      this.classificationNcr.type = null;
      this.showModal = false;
    },
    openModalForRemoval({ id, name }) {
      this.showModalForRemoval = true;
      this.classificationId = id;
      this.nameRuleSelected = name;
    },
  },
};
</script>
<style scoped>
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-item {
  margin: 0.5rem;
}

.item-row {
  cursor: pointer;
}
.ghost {
  background-color: #ff9f43;
  opacity: 1;
  color: #ffffff;
}
.drag {
  background-color: #ff9f43;
  color: #ffffff;
}
.grow-up {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grow-up::before,
.grow-up::after {
  content: "";
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: 1px solid #ff9f43 !important;
  border-radius: 8px;
  position: absolute;
  animation: grow 1s ease-in-out infinite;
}
.grow-up::after {
  width: calc(100% + 18px);
  height: calc(100% + 18px);
}
@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.btn-primary .custom-icon,
.btn-secondary .custom-icon,
.btn-success .custom-icon,
.btn-warning .custom-icon,
.btn-danger .custom-icon,
.btn-info .custom-icon {
  filter: brightness(0) invert(1);
}
</style>
